import React, { useState } from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layouts/courseLandingLayout'
import styled from 'styled-components';
import PSModal from '../../components/modal';
import Button from '../../components/buttons/linkButton';
import { Container, Col, Row } from 'react-bootstrap';
import Navbar from '../../components/navbars/courseLandingNavbarNoMenu';
import ReactGA from 'react-ga'

const HeroBackground = styled.div`
  display: block;
  background: none;
  background-size: cover;
  background-position: center center;
  background-color: var(--blue);
`

const HeroContainer = styled(Container)`
  padding-top: 150px;
  padding-bottom: 50px;
  height: 100%;
  h1 {
    color: var(--white);
    text-align: center;
    margin-bottom: 150px;
  }
  h5 {
    color: var(--white);
    padding-top: 15px;
    font-family: 'roboto-mono';
  }
  img {
    border: 4px solid var(--black);
    box-shadow: -15px 15px var(--black);
  }
  p {
    color: var(--white);
    padding-top: 20px;
    font-size: 16px;
  }

  @media screen and (max-width: 767px) {
    h1, p {
      color: var(--white);
    }

    .cta-hero-invite {
      margin-bottom: 20px;
    }
  } 
`

const VideoSection = styled.section`

`

const VideoContainer = styled(Container)`
  margin-top: -200px;
  padding-top: 80px;
  padding-bottom: 60px;
  max-width: 800px;
  h3 {
    text-align: center;
  }
  .cta-container {
    text-align: center;
  }
  img {
    border: 4px solid var(--black);
    box-shadow: -15px 15px var(--black);
  }
`

const Video1 = ({location}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (e) => setShow(true);

  const fireGTMEvent = () => {
    ReactGA.event({
      category: 'CTA - Button',
      action: 'Click',
      label: 'apply-video',
    })
  };

  return (
    <Layout>
      <HeroBackground>
        <Navbar location={location}/>
        <HeroContainer>
          <Row>
            <Col md={12}>
              <h1>The Cost of Doing Nothing_</h1>
            </Col>
          </Row>
        </HeroContainer>
      </HeroBackground>
      <VideoSection>
        <VideoContainer>
          <Row>
            <Col className='curriculum-left' md={12}>
              <iframe width="100%" height="435" src="https://www.youtube.com/embed/cqj-hyIv1SU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen='true'></iframe>
              <div className='cta-container'>
                <Link to='/apply'>
                  <Button fireGTMEvent={fireGTMEvent} handleCTAClick={handleShow} text={'Apply Now'} />
                </Link>
              </div>
            </Col>
          </Row>
        </VideoContainer>
      </VideoSection>
    </Layout>
  )
}

export default Video1
